import { Card, CardHeader, Container, Paper } from "@mui/material";
import { Link, navigate } from "gatsby";
import { useSnackbar } from "notistack";
import React from "react";
import { useEffect } from "react";
import { ReservationForm } from "../../components/ReservationForm";
import {
  useDeleteReservationMutation,
  useReservationByIdSubscription,
  useUpdateReservationMutation,
} from "../../__generated__/types";
import { difference } from "ramda";
import { Button } from "gatsby-theme-material-ui";
import { translations } from "../../translations";
import { useSelectedPropertyContext } from "../../components/SelectedPropertyProvider";

const ReservationIdPage = (props) => {
  const { id } = props.params;
  const { selected_locale } = useSelectedPropertyContext();
  const reservationsQuery = useReservationByIdSubscription({
    variables: { id },
  });
  const [updateReservation] = useUpdateReservationMutation();
  const [deleteReservation] = useDeleteReservationMutation();
  const { enqueueSnackbar } = useSnackbar();
  if (reservationsQuery.error) {
    return <div>{reservationsQuery.error?.message}</div>;
  }
  if (reservationsQuery.loading) {
    return null;
  }
  return (
    <Container sx={{ mt: 3, mb: 2 }}>
      <Paper sx={{ p: 2 }}>
        <ReservationForm
          initialValues={{
            arrival_date: reservationsQuery.data.reservation_by_pk.arrival_date,
            departure_date:
              reservationsQuery.data.reservation_by_pk.departure_date,
            rooms:
              reservationsQuery.data.reservation_by_pk.reservation_rooms.map(
                ({ room }) => room.id
              ),
            note: reservationsQuery.data.reservation_by_pk.note,
            status: reservationsQuery.data.reservation_by_pk.status,
            pets: reservationsQuery.data.reservation_by_pk.pets,
            client: {
              first_name:
                reservationsQuery.data.reservation_by_pk.client.first_name,
              last_name:
                reservationsQuery.data.reservation_by_pk.client.last_name,
              phone_number:
                reservationsQuery.data.reservation_by_pk.client.phone_number,
              email: reservationsQuery.data.reservation_by_pk.client.email,
            },
          }}
          onSubmit={async (
            { rooms, arrival_date, departure_date, note, client, status, pets },
            actions
          ) => {
            const roomIds =
              reservationsQuery.data.reservation_by_pk.reservation_rooms.map(
                ({ room }) => room.id
              );
            const insertedRooms = difference(rooms, roomIds);
            const deletedRooms = difference(roomIds, rooms);
            await updateReservation({
              variables: {
                id,
                reservation_set_input: {
                  arrival_date,
                  departure_date,
                  note,
                  status,
                  pets,
                },
                reservation_rooms_insert_input: insertedRooms.map((i) => ({
                  room_id: i,
                  reservation_id: id,
                })),
                reservation_rooms_bool_exp: {
                  _or: deletedRooms.map((i) => ({
                    reservation_id: { _eq: id },
                    room_id: { _eq: i },
                  })),
                },
                ...(deletedRooms.length === 0 && {
                  reservation_rooms_bool_exp: { _not: {} },
                }),
                client_id: reservationsQuery.data.reservation_by_pk.client.id,
                client_set_input: {
                  first_name: client.first_name,
                  last_name: client.last_name,
                  phone_number: client.phone_number,
                  email: client.email,
                },
              },
            });
            actions.setSubmitting(false);
            actions.resetForm();
            enqueueSnackbar(translations[selected_locale].reservation_updated, {
              variant: "success",
            });
          }}
          onDelete={async (values) => {
            const accepted = prompt(
              `${translations[selected_locale].name_prompt} (${values.client.first_name} ${values.client.last_name})`
            );
            if (
              accepted !== null &&
              accepted ===
                `${values.client.first_name} ${values.client.last_name}`
            ) {
              await deleteReservation({ variables: { id } });
              navigate(-1);
            }
          }}
        />
      </Paper>
      <Link
        to={`/invoice/${reservationsQuery.data?.reservation_by_pk?.invoice?.id}`}
        style={{ textDecoration: "inherit" }}
      >
        <Card sx={{ mt: 2 }}>
          <CardHeader
            title={translations[selected_locale].invoice}
            action={
              <Button variant="contained" color="secondary">
                {translations[selected_locale].view}
              </Button>
            }
          />
        </Card>
      </Link>
    </Container>
  );
};
export default ReservationIdPage;
